import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Header from '../components/Header/HeaderFrontpageVideo'
import Button from '../components/Button/Button'
import PortfolioTeaser from '../components/PortfolioTeaser/PortfolioTeaser'
import ClientSection from '../components/ClientSection/ClientSection'
import ContactBoxFullWidth from '../components/ContactBoxFullWidth/ContactBoxFullWidth'
import ImgBenefits from '../images/svgs/benefits.svg'
import ImgIntro from '../images/svgs/intro.svg'
import ImgStageHome from '../images/svgs/stage_home_min.svg'
import '../components/ServicesOverview/ServicesOverview.css'
import ServiceOverview from '../components/ServicesOverview/ServicesOverview'
import { withPrefix, Link } from "gatsby"

const IndexPage = ({ data }) => {
  const { slug, titleStage, titleIntrotextSection, titleIntrotext, titleAboutSection, titleAboutTeasertext, titleBenefits, titleBenefitsSection, titlePortfolioSection, titlePortfolio } = data.contentfulFrontpage
  const content_shortIntrotext = data.contentfulFrontpage.childContentfulFrontpageShortIntrotextTextNode.shortIntrotext
  const content_shortAboutTeasertext = data.contentfulFrontpage.childContentfulFrontpageShortAboutTeasertextTextNode.shortAboutTeasertext
  const content_bulletpointsBenefits = data.contentfulFrontpage.childContentfulFrontpageBulletpointsBenefitsRichTextNode.json

  return (
    <Layout>
      <SEO title={titleStage} />
      <link
      rel="stylesheet"
      type="text/css"
      charset="UTF-8"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
    />
      <Header titleBig={titleStage} teaserText={content_shortIntrotext} color='darkgrey' textcolor='text-white' className='stage-home-big'>
        <ImgStageHome />
      </Header>
      <ClientSection className=''/>
      
    

      <div className='flex w-full flex-wrap xs:my-16 lg:my-32 lg:mb-16 px-0 justify-center '>
        <div className='flex w-full text-center'>
          <span className='category-title mx-auto text-center'>{titlePortfolioSection}</span>
        </div>
        <div className='flex w-full text-center max-w-2xl mt-2'>
          <h2 className='leading-relaxed mx-auto text-center'>{titlePortfolio}</h2>
        </div>
        <div className='flex w-full xs:mt-8 md:mt-20 flex-wrap justify-center'>
          <PortfolioTeaser />
        </div>

        
      </div>
      <div className="mt-16"></div>
    </Layout>
  )
}

export default IndexPage

export const FrontpageQuery = graphql`
query {
  contentfulFrontpage(slug: {eq: "start"}) {
    slug
    titleStage
    titleIntrotextSection
    titleIntrotext
    titleAboutSection
    titleAboutTeasertext
    titleBenefitsSection
    titlePortfolioSection
    titlePortfolio
    slug
    childContentfulFrontpageShortIntrotextTextNode {
      shortIntrotext
    }
    childContentfulFrontpageShortAboutTeasertextTextNode {
      shortAboutTeasertext
    }
    titleBenefits
    childContentfulFrontpageBulletpointsBenefitsRichTextNode {
      json
    }
  }
}
`