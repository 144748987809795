import React from 'react'
import PropTypes from 'prop-types'

import './HeaderFrontpage.css'
import Button from '../Button/Button'
import StartVid from '../../images/vid-start.mp4'
import VidPoster from '../../images/vid-poster.jpg'

const Header = ({ className, titleBig, teaserText ,color, textcolor, children }) => {
  return (
    <>
      <header className={`w-full px-4 md:px-8 pb-6 lg:pb-16 pt-4 md:pt-8 xl:pt-16 flex justify-center video bg-${color} ${className}`}>
        <div className={`flex flex-wrap w-full max-w-6xl xl16:relative`}>
          <div className={`flex flex-col relative md:justify-center w-full md:w-6/12 xs:mt-8 md:mt-0 md:pr-8 wrapper-stage-title`}>
              <h1 className={`stage-title w-full self-start hyphens-manual mb-8 md:mb-0 ${textcolor}`}>Per Anhalter zur Digitali&shy;sierung.</h1>
              <div id="stage-img-mobile" className="mobile-only hidden">{children}</div>
          </div>
          <div id='stage-img' className='relative md:absolute overflow-x-hidden right-0 w-full md:w-3/5 mt-0'>
            <video loop autoplay muted buffered autoplay="autoplay" poster={VidPoster}>
              <source src={StartVid} type="video/mp4" autoplay/>
            </video>
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  titleBig: PropTypes.string,
  teaserText: PropTypes.string,
  color: PropTypes.string,
  textcolor: PropTypes.string,
  children: PropTypes.node,
}

Header.defaultProps = {
  color: 'cream',
}

export default Header
